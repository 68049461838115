<template>
  <div>
    <el-dialog v-model="posVisible" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" width="70%">
      <template #title>
        <span style="color: white; font-size: 20px">选择位置</span>
        <el-button size="small" style="margin-left: 5px; float: right" @click="search">搜索</el-button>
        <el-input v-model="condition" placeholder="安装位置" prefix-icon="el-icon-search"  size="small" style="width: 200px; float: right" ></el-input>
      </template>

      <el-table :data="dataList" border style="width: 100%; border-radius: 10px" :empty-text="emptyText">
        <el-table-column type="index" label="序号" width="60" align="center " />
        <el-table-column prop="name" label="安装位置" align="center" />
        <el-table-column prop="address" label="详细地址" align="center" >
          <template #default="scope">
            {{ scope.row.address != "" ? scope.row.address.replace(/#/g, "") : "--"}}
          </template>
        </el-table-column>
        <el-table-column label="选择" width="100" align="center ">
            <template #default="scope">
              <el-radio v-model="index" :label="scope.$index" >&nbsp;</el-radio>
            </template>
          </el-table-column>
      </el-table>

      <div class="pagination">
        <ls-pagination :currentPage="currentPage" :page-size="size" :total="total" @size-change="handleSizeChange" @cur-change="handleCurrentChange"></ls-pagination>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel" size="small">取消</el-button>
          <el-button type="primary" @click="sure" size="small">确定</el-button>
        </span>
      </template>
    </el-dialog>

   
  </div>
</template>

<script>
import {getInPosInfos} from '../../../../api/api'
import session from '../../../../store/store'
export default {
  data() {
    return {
      emptyText:'加载中',
      posVisible: true,
      condition:'',
      index:-1,
      currentPage: 1,
      size: 10,
      total: 0, //总数
      dataList: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getInPosInfos();
    },
    search() {
      this.getInPosInfos();
    },
    getInPosInfos() {
      var param = {
        uuid:session.getLoginInfo().unitUuid,
        page:this.currentPage - 1,
        size:this.size,
        condition:this.condition,
      }
      getInPosInfos(param).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.count;
          this.dataList = res.data.dataInfo;
        }else {
          this.total = 0;
          this.dataList.splice(0, this.dataList.length);
          this.emptyText = "暂无数据"
        }
      })
    },
    sure() {
      if(this.index == -1) {
        this.$message.warning({ message: "请先选择安装位置"});
        return;
      }
      this.$emit("update",this.dataList[this.index]);
      this.cancel();
    },
    cancel() {
      this.posVisible = false
      this.$emit("cancel");
    },

    handleSizeChange(val) {
      this.size = val;
      this.getInPosInfos();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getInPosInfos();
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  text-align: center;
  margin: 20px 0px;
}

// .input {
//   width: 50%;
// }
hr {
  border-color: #f4f4f526;
}
.page {
  padding: 0px 20px;
}
.el-form-item {
  margin-bottom: 0px !important;
  margin-left: -20px;
}
.row {
  margin: 20px 0px 20px 0px;
}
.box {
  padding: 20px;
  width: 75%;
  background-color: white;
}
.title {
  margin-right: 25px;
  font-size: 16px;
}

.content {
  font-size: 16px;
  margin-left: 8px;
}
</style>

<style>
.el-radio__inner {
    border-color:#FF6F25;
}
</style>
