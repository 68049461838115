<template>
  <div class="page">
    <ls-title-header @back="cancel" title="设备管理"></ls-title-header>
    <div class="box">
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="设备信息" name="first">
          <AioBaseInfo ref="baseView" @cancel="cancel"></AioBaseInfo>
        </el-tab-pane>
        <el-tab-pane label="使用记录" name="second">
          <AioRecord ref="recordView" @cancel="cancel"></AioRecord>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import AioBaseInfo from "./AioBaseInfo.vue";
import AioRecord from "./AioRecord.vue";
export default {
  components: {
    AioBaseInfo,
    AioRecord
  },
  data() {
    return {
      deviceData:null,
      activeName: "first",
    };
  },
  methods: {
    getParentData(deviceData) {
      this.deviceData = deviceData;
      setTimeout(() => {
        this.$refs.baseView.getParentData(deviceData);
      }, 200);
    },
    cancel() {
      this.$emit("cancel");
    },
    handleClick(tab) {
      if(0 == tab.index) {
        setTimeout(() => {
          this.$refs.baseView.getParentData(this.deviceData);
        }, 200);
      }else if(1 == tab.index) {
        setTimeout(() => {
          this.$refs.recordView.getParentData(this.deviceData);
        }, 200);
      }
    },
  },
};
</script>

<style scoped>
.page {
  padding: 0px 20px;
}
.box {
  /* width: 75%; */
  padding: 20px 20px;
  background-color: white;
  border-radius: 4px;
}
</style>
<style>
.el-tabs__item {
  font-size: 18px;
}
.el-tabs__nav-wrap::after {
  position: static !important;
}
</style>