<template>
  <div>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules"  label-width="140px" class="demo-ruleForm" >
      <div style="background-color: white">
        <div class="box">

          <el-row class="row">
            <el-col :span="24">
                <el-form-item>
                  <template #label><span class="title">绑定日期</span></template>
                  <span class="content">{{ruleForm.createTime}}</span>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row" v-if="this.role == 1">
            <el-col :span="24">
              <el-form-item>
                <template #label><span class="title">所属机构</span></template>
                <span class="content">{{deviceData.unitName}}</span>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="24">
                <el-form-item>
                  <template #label><span class="title">设备编号</span></template>
                  <span class="content">{{ruleForm.deviceId}}</span>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="24">
                <el-form-item>
                  <template #label><span class="title">设备名称</span></template>
                  <span class="content">{{ruleForm.deviceName}}</span>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="24">
                <el-form-item>
                  <template #label><span class="title">设备类型</span></template>
                  <span class="content">{{ruleForm.deviceType}}</span>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="24">
                <el-form-item>
                  <template #label><span class="title">设备型号</span></template>
                  <span class="content">{{ruleForm.deviceModel}}</span>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="name">
                  <template #label><span class="title">信息备注</span></template>
                  <el-input v-show="edit == true" v-model="ruleForm.remarks" placeholder="请输入信息备注"  class="input"></el-input>
                  <span v-show="edit == false" class="content">{{ruleForm.remarks}}</span>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="positionName">
                  <template #label><span class="title">位置名称</span></template>
                  <el-input v-show="edit == true" v-model="ruleForm.positionName" placeholder="请选择安装位置" class="input" >
                    <template #append>
                      <el-button @click="this.posVisible = true" type="primary" style="color: white" >选择安装位置</el-button>
                    </template>
                  </el-input >
                  <span v-show="edit == false" class="content">{{ruleForm.positionName}}</span>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="positionAddress">
                  <template #label><span class="title">安装地址</span></template>
                  <span v-show="edit == false" class="content">{{ ruleForm.positionAddress != "" ? ruleForm.positionAddress.replace(/#/g, "") : "--"}}</span>
                  <div v-show="edit != false">
                    <ls-address  style="display: flex;justify-content: space-between;width:100%;" @adr-change="getAddress" :province="this.address.province" :city="this.address.city" :district="this.address.district" :street="this.address.street"></ls-address>
                  </div>
                 
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row" v-show="edit != false">
            <el-col :span="24">
                <el-form-item prop="name">
                  <el-input v-model="this.address.detail" placeholder="请输入详细地址" maxlength="50" show-word-limit class="input"></el-input>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="24">
              <el-form-item>
                <el-button v-show="(edit == false)&&(this.role != 1)" type="primary" size="small" @click="editAio" >编辑</el-button >
                <el-button v-show="edit == true" type="primary" size="small"  @click="cancelEdit" plain >取消</el-button>
                <el-button v-show="edit == true" type="primary" size="small" @click="submitForm('ruleForm')">提交</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-form>

    <template v-if="posVisible">
      <AioAddPos @update="updatePosData" @cancel="this.posVisible = false"></AioAddPos>
    </template>
  </div>
  
</template>

<script>
import {getAioInfo,editAioInfo} from '../../../../api/api'
import session from "../../../../store/store";
import util from "../../../../until/until";
import AioAddPos from './AioAddPos.vue'
export default {
  components: {
    AioAddPos,
  },
  data() {
    return {
      posVisible: false,
      edit: false,
      role: session.getLoginInfo().accountType,
      deviceData:{
        unitName:'',
      },

      ruleForm: {
        deviceId: '',
        deviceName:'',
        deviceType:'',
        deviceModel:'',
        remarks:'',
        positionUuid:'',
        positionName:'',
        positionAddress:'',
      },
      address: {
          province:'',
          city:'',
          district:'',
          street:'',
          detail:'',
      },
      rules: {
        positionName:[
          {required: false,message: "请选择安装位置", trigger: "blur"},
        ],
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
    },
    getParentData(deviceData) {
      this.deviceData = deviceData;
      this.getAioInfo();
    },
    getAioInfo() {
      let param = {
        deviceId:this.deviceData.deviceId,
      }
      getAioInfo(param).then(res => {
        if (res.data.code == 200) {
          this.ruleForm = res.data.dataInfo;

          let addressArr = this.ruleForm.positionAddress.split("#");
          if(addressArr.length == 5) {
            this.address.province = addressArr[0];
            this.address.city = addressArr[1];
            this.address.district = addressArr[2];
            this.address.street = addressArr[3];
            this.address.detail = addressArr[4];
          }
        }
      })
    },
    getAddress(data){
      this.address.province = data.selectProvince;
      this.address.city = data.selectCity;
      this.address.district =  data.selectDistrict;
      this.address.street = data.selectStreet
    },
    updatePosData(data) {
      this.ruleForm.positionUuid = data.uuid;
      this.ruleForm.positionName = data.name;
      this.ruleForm.positionAddress = data.address;

      if(!util.isNULL(data.address)) {
        let addressArr = data.address.split("#");
        if(addressArr.length == 5) {
          this.address.province = addressArr[0];
          this.address.city = addressArr[1];
          this.address.district = addressArr[2];
          this.address.street = addressArr[3];
          this.address.detail = addressArr[4];
        }
      }
    },
    editAio() {
      this.edit = true
    },
    cancelEdit() {
      this.edit = false;
    },
    submitForm(ruleName) {
      this.$refs[ruleName].validate((valid) => {
        if (valid) {
         this.editAioInfo();
        } else {
          return false;
        }
      });
    },
    editAioInfo() {
      this.ruleForm.unitUuid = session.getLoginInfo().unitUuid,
      editAioInfo(this.ruleForm).then(res => {
        if (res.data.code == 200) {
          this.$message({ message: '修改成功',  type: 'success'});
          this.cancelEdit();
        } else {
          this.$message.error({ message: res.data.message});
        }
      })
    },

  },
};
</script>

<style lang="scss" scoped>
.pagination {
  text-align: center;
  margin: 20px 0px;
}
.el-form-item {
  margin-bottom: 0px !important;
  margin-left: -19px;
}
.input {
  width: 100%;
}
hr {
  border-color: #f4f4f526;
}
.title {
  margin-right: 25px;
  font-size: 16px;
  color: #8D8D8D;
}
.box {
  width: 75%;
}
.content {
  font-size: 16px;
  margin-left: 10px;
}
.row {
  margin: 20px 0px 20px 0px;
}


</style>
